.separation_margin{
margin: 0;
width: 102%;
}

.employee_cards{
  /* text-align: center; */
}

.employee_card{
  box-shadow: 1px 1px 5px #00000029;
  border: 1px solid #D4D4D4;
border-radius: 8px;
display: inline-block;
margin-right: 1.5rem!important;
}


.employee_card_image img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.employee_card_image{
  margin: auto;
  width: 4.5rem;
  height: 4.5rem;
}

.employee_card_name{
  text-align: center;
  font-size: 14px;
  font-family: 'Poppins';
  letter-spacing: 0px;
color: #2D2D2D;
  margin-top: .5rem;
  margin-bottom: 0;
}

.employee_card_role{
  background-color: #E1EFFF !important;
border-radius: 4px;
font-family:  'Poppins';
font-weight: 400;
font-size: 11px;
letter-spacing: 0px;
color: #144AB7;
border: none;
padding: .2rem 1rem;

}

.employee_card_phone{
  margin-top: 1.5rem;
  font-size: 14px;
  margin-bottom: 0;
  color: #2D2D2D;
}
.employee_card_email{
  font-size: 14px;
  color: #2D2D2D;
  font-family: 'Poppins';
  margin-bottom: 0;
}

.employee_card_btn{
  background: #265BC4 0% 0% no-repeat padding-box;
box-shadow: 3px 3px 6px #00000029;
border-radius: 4px;
font-size: 11px;
font-family: 'Poppins';
letter-spacing: 0px;
color: #ffffffd5;
width: 80%;
border: none;
padding-top:.3rem ;
padding-bottom:.3rem ;
margin-top: 1.5rem;
margin-bottom: 1rem;

}