.first_head {
  font-weight: 600;
}

.first_subhead {
  margin-top: -10px;
  letter-spacing: 0.06px;
  color: #858585;
  opacity: 0.7;
  font-weight: 500;
}

.main_Header {
  width: 37% !important;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
}

.skill-input {
  border: none;
  outline: none !important;
  /* outline: solid 1px black !important; */
  -webkit-appearance: none;
}

.skill-input:focus {
  border: none !important;
  outline: none !important;
  -webkit-appearance: none;
}

.skill-input:active {
  border: none !important;
  outline: none !important;
}

.skill-input:hover {
  border: none !important;
  outline: none !important;
}

tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}
.tag-title {
  margin-top: 3px;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
