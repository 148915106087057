/* Header */
.head_name {
  font-size: 32px;
  letter-spacing: 0.1px;
  color: #171725;
  margin-top: 1rem;
}
.head_description {
  text-align: left;
  font: normal normal normal 14px/36px Poppins;
  line-height: 0.85rem;
  font-size: 12px;
  letter-spacing: 0.06px;
  color: #858585;
  margin-top: -16px;
}
.head_btn {
  background: #265bc4 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 4px;
  font: normal normal normal 12px/14px Poppins;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 145px;
  height: 35px;
  margin-top: 2.5rem;
  float: right;
  position: relative;
  right: -1.5rem;
}

.head_btn:hover {
  color: white;
}

/* Search */
.search_filter {
  font: normal normal medium 18px/36px Poppins;
  font-size: 17px;
  letter-spacing: 0.07px;
  color: #171725;
}
.search_filter_desc {
  font: normal normal normal 12px/18px Poppins;
  font-size: 11px;
  letter-spacing: 0.05px;
  color: #858585;
}
.search_icon {
  font: normal normal normal 14px/20px Poppins;
  font-size: 12px;
  letter-spacing: 0px;
  color: #265bc4;
}
.search_btns,
.search_btns:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000029;
  border: 1px solid #265bc4 !important;
  color: #265bc4 !important;
  font: normal normal normal 12px/14px Poppins;
  font-size: 11px;
  letter-spacing: 0px;
  border-radius: 4px;
  width: 84px;
  height: 31px;
}

.search_margin {
  margin: 0;
}

.row .width_inc {
  width: 18%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.jobs_left .col-3,
.jobs_left .col-2 {
  padding-left: 0;
  padding-right: 0;
}

.sort_icon {
  float: right;
  margin-right: 1rem;
}

.jobs_found {
  letter-spacing: 0.06px;
  color: #265bc4;
  font: normal normal medium 14px/36px Poppins;
  font-size: 13px;
  font-weight: 600;
  margin-top: 2rem;
}

.job_table {
  height: 35rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 3rem;
}
.job_table::-webkit-scrollbar {
  display: none;
}

.job_title {
  font: normal normal medium 16px/36px Poppins;
  font-size: 16px;
  letter-spacing: 0.07px;
  color: #0a0a0a;
}
.job_subtitle {
  font: normal normal medium 12px/36px Poppins;
  font-size: 12px;
  letter-spacing: 0.05px;
  color: #858585;
  margin-bottom: 8px;
}
.last_updated {
  font: normal normal medium 12px/36px Poppins;
  font-size: 12px;
  float: right;
  letter-spacing: 0.05px;
  color: #858585;
}

.job_img {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
  margin-top: 16px;
}

.job_tags {
  font: normal normal medium 10px/16px Poppins;
  font-size: 10px;
  margin-right: 1rem;
  letter-spacing: 0.04px;
  color: #265bc4;
  background-color: #e1efff;
}

.jobs_right {
  padding-right: 0;
  position: relative;
  right: -1rem;
}

.jobs_right .card {
  padding-left: 0.5rem !important;
}

.job_description {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.job_description_heading {
  font: normal normal medium 16px/36px Poppins;
  letter-spacing: 0.07px;
  color: #0a0a0a;
  font-size: 16px;
}

.job_description_text {
  font: normal normal normal 11px/17px Poppins;
  font-size: 11px;
  letter-spacing: 0.05px;
  color: #000000;
}
