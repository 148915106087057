.nav_bar{
  padding: .5rem 2rem;
  border-bottom: 1px solid rgba(0,0,0,.05);
  background-color: #FFFFFF;
  z-index: 1;
  padding-right: 0rem;
  max-height: 10vh;
}
.nav_bar_name{
  width: 75px;
height: 27px;
/* UI Properties */
text-align: left;
font: normal normal 600 20px/27px Segoe UI;
font-size: 20px;
letter-spacing: 0px;
color: #265BC4;
opacity: 1;
}
/* .nav_bar_links{
  float: right;
} */
.nav_bar_user,.nav_bar_user:hover{
  width: fit-content;
height: 19px;
/* UI Properties */
text-align: left;
font: normal normal normal 14px/20px Segoe UI;
letter-spacing: 0px;
color: #131523;
opacity: 1;
}
.badge {
  position: relative;
  top: -20px;
  left: -25px;
  border: 1px solid black;
  border-radius: 50%;
 }
 .notify,.notify:hover{
 color: black;
 margin-top: 1rem;
 margin-right: 1rem;
 }
 .notify_item{
   margin-top: .5rem;
   margin-right: 1.5rem;
   width: 30px;
height: 30px;
font-size: 1.2rem;
 }
 .num_badge{
   background-color: #265BC4;
   border-radius: 0!important;
   font: normal normal normal 10px/12px Segoe UI !important;
   font-size: 10px;
   letter-spacing: 0.07px;
   color: #FFFFFF;
   opacity: 1;
   padding: 2px;
   border: none;
   outline: none;
 }

 .nav_img_div{
   /* width: 32px!important;
   height: 32px!important; */
   margin-bottom: 0rem;
   margin-right: 1rem;
   float: left;
 }
 .nav_img{
   width: 100%;
   height: 100%;
   border-radius: 100%;
 }

 .user_down{
   margin-left: 1rem;
   margin-right: 1rem;
 }