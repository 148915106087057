.wrapper {
    position: relative;
    .tooltip {
      width: 90px;
      height: 45px;
      border-radius: 10px;
      position: absolute;
      background: black;
      z-index: 1;
      padding: 0 15px;
      box-shadow: 0 10px 30px rgba(#414856, 0.05);
      opacity: 100;
      top: 0;
      left: 0;
      margin-left: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      transition: opacity .15s ease-in, top .15s ease-in, width .15s ease-in;
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        background: black;
        border-radius: 1px;
        position: absolute;
        // left: 50%;
        left: -1px;
        transform: rotate(45deg);
        z-index: 100;
      }
    }
    .show-tool-tip {
      &:hover {
        ~ svg {
          animation: pang-animation 1s ease-out forwards;
          .shape {
            @for $shape from 1 through 9 {
              &:nth-of-type(#{$shape}) {
                transform: translate(random(50) - 25 + px, 30%) rotate(40deg*$shape);
              }
            }
          }
        }
        ~ .tooltip {
          width: 190px;
          height: 70px;
          animation: stretch-animation 0.7s ease-out forwards .15s;
          left: 100px;
          opacity: 1;
        }
      }
    }
  }
  
  @keyframes pang-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.1);
      opacity: 0;
    }
  }
  @keyframes plus-animation {
    0% {
      transform: rotate(0) scale(1);
    }
    20% {
      transform: rotate(60deg) scale(.93);
    }
    55% {
      transform: rotate(35deg) scale(.97);
    }
    80% {
      transform: rotate(48deg) scale(.94);
    }
    100% {
      transform: rotate(45deg) scale(.95);
    }
  }
  @keyframes plus-animation-reverse {
    0% {
      transform: rotate(45deg) scale(.95);
    }
    20% {
      transform: rotate(-15deg);
    }
    55% {
      transform: rotate(10deg);
    }
    80% {
      transform: rotate(-3deg);
    }
    100% {
      transform: rotate(0) scale(1);
    }
  }
  @keyframes stretch-animation {
    0% {
      transform: scale(1,1)
    }
    10% {
      transform: scale(1.1,.9)
    }
    30% {
      transform: scale(.9,1.1)
    }
    50% {
      transform: scale(1.05,.95)
    }
    100% {
      transform: scale(1,1)
    }
  }