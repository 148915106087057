@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 10px;
  height: 10px;
  max-height: 10px;
  background-color: rgb(71 85 105);
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3); */
  background-color: rgb(255, 255, 255);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: #88888a;
}

