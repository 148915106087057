.save_emp_data_btn {}

.save_emp_data_btn {
  background-color: #265BC4 !important;
  color: white;
  text-transform: capitalize !important;
  width: 14rem;
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 1rem !important;



}

.save_emp_data_btn:hover {
  background-color: #2c68e0 !important;
  color: white;
}

.save_emp_data_btn:active,
.save_emp_data_btn:focus {
  color: white !important;
}

.css-itvipi-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  color: white !important;
}