.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background-color: #265bc4;
  opacity: 0.72;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head-blue {
  background-color: rgb(255, 255, 255);
}

.timeline_icons {
  border: 1px solid black;
  border-radius: 50%;
  color: white;
  width: 36px;
  height: 36px;
}

.timeline_icon_active {
  border: 1px solid #265bc4;
  border-radius: 50%;
  color: white;
}

.active_number {
  color: #265bc4;
}

.not_active {
  color: black;
}

.timeline_icon_div1 {
  position: relative;
}

.timeline_icon_div1 span {
  position: absolute;
  top: 0.4rem;
  right: 0.97rem;
}

.timeline_icon_div2 {
  position: relative;
}

.timeline_icon_div2 span {
  position: absolute;
  top: 0.35rem;
  right: 0.87rem;
}

.timeline_icon_div3 {
  position: relative;
}

.timeline_icon_div3 span {
  position: absolute;
  top: 0.55rem;
  right: 0.66rem;
}

.timeline_icon_div4 {
  position: relative;
}

.timeline_icon_div4 span {
  position: absolute;
  top: 0.55rem;
  right: 0.66rem;
}
.add_required_skill {
  color: #265bc4;
  font-weight: bold;
  transition: all 0.7s;
}

.add_required_skill span {
  font-size: 18px;
}

.add_required_skill:hover {
  color: #396bcf;
  cursor: pointer;
}

.delete_icon {
  color: #d93b3b;
}

.delete_icon:hover {
  cursor: pointer;
  color: #f45555;
}

.main_box {
  position: relative;
  margin-right: 25rem !important;
}

.BTN_GROUP {
  justify-content: center;
}
.newjob_subheading {
  font-size: 1rem;
  opacity: 0.9;
}

.fixed_sidebar {
  position: fixed;
  right: 0;
  margin-top: -2rem !important;
}
.done_tick {
  border-radius: 50%;
  padding: 0.8rem 0.83rem;
  color: white;
  background-color: #265bc4;
}
