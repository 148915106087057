.svg-success {
  display: inline-block;
  vertical-align: top;
  height: 80px;
  width: 80px;
  opacity: 1;
  overflow: visible;

  @keyframes success-tick {
    0% {
      stroke-dashoffset: 16px;
      opacity: 1;
    }

    100% {
      stroke-dashoffset: 31px;
      opacity: 1;
    }
  }

  @keyframes success-circle-outline {
    0% {
      stroke-dashoffset: 72px;
      opacity: 1;
    }

    100% {
      stroke-dashoffset: 0px;
      opacity: 1;
    }
  }

  @keyframes success-circle-fill {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .success-tick {
    fill: none;
    stroke-width: 1px;
    stroke: #ffffff;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    animation: success-tick 450ms ease 1400ms forwards;
    opacity: 0;
  }

  .success-circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #1c64f2;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
  }

  .success-circle-fill {
    fill: #1c64f2;
    stroke: none;
    opacity: 0;
    animation: success-circle-fill 300ms ease-out 1100ms forwards;
  }

  // Can't animate stroke-dashoffset on IE 10 and 11, just show svg instead
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    .success-tick {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: none;
      opacity: 1;
    }

    .success-circle-outline {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: none;
      opacity: 1;
    }

    .success-circle-fill {
      animation: none;
      opacity: 1;
    }
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #e12828;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #e12828;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 29;
  stroke-dashoffset: 29;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #e12828;
  }
}
